<template>
  <div>
    <slot :teacherData="getterTeachDataValue || teacherData"></slot>
  </div>
</template>

<script>
/**
 * 数据字典下拉选择组件
 */
import { mapGetters, mapActions } from "vuex";
import * as dictConstant from "@/utils/dict";

export default {
  props: {
    dictModel: null,
    refresh: {
      default: false,
    },
    code: {
      default: null,
    },
  },
  data() {
    return {
      teacherData: [],
    };
  },
  computed: {
    ...mapGetters(["getterTeacherData"]),
    getterTeachDataValue() {
      let arr = [];
      arr = this.getterTeacherData;
      // console.log(arr)
      return arr;
    },
  },
  methods: {
    ...mapActions(["getTeacherData"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.getTeacherData().then((res) => {
        this.teacherData = res
      });
    });
  },
};
</script>
<style>
</style>
