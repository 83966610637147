<template>
  <span>
    <HqDrawer
      title="选择学员"
      :visible.sync="drawerStudent"
      size="70%"
      ref="drawer"
      @on-cancel="onCancel"
      :modal="false"
    >
      <div class="ty-drawer-content">
          <div class="ty-try-header">
            <el-row :gutter="24" style="margin-top: 18px">

              <el-col :span="4">
                  <v-text-field
                    v-model="studentName"
                    label="学员姓名"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                  ></v-text-field>
                </el-col>
                <el-col :span="4">
                  <v-text-field
                    v-model="mobile"
                    label="学员手机号"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                  ></v-text-field>
                </el-col>
              
                <el-col :span="4">
                  <v-text-field
                    v-model="orderNo"
                    label="订单编号"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                  ></v-text-field>
                </el-col>

                <el-col :span="8" class="searchBtn">
                  <el-button size="small" type="primary" @click="search">查询</el-button>
                  <el-button size="small" @click="reset">重置</el-button>
                </el-col>

            </el-row>
            </div>


          <div class="ty-page-list ty-drawer-list">
              <!-- 列表区 -->
              <el-table
                ref="singleTable"
                style="width: 100%; margin-bottom: 24px"
                border
                stripe
                size="mini"
                v-loading="listLoading"
                element-loading-text="拼命加载中"
                :header-cell-style="{ background: '#e5f2ff' }"
                sortable
                highlight-current-row
                header-cell-class-name="normal-table-header"
                :height="tableHeight"
                :data="tableData"
                @current-change="onCurrentChange"
                @row-click="handleRowClick"
                @selection-change="handleSelectionChange"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
              >
                  <!-- <el-table-column :show-overflow-tooltip="true" width="40px">
                    <el-checkbox :checked="true" disabled></el-checkbox>
                  </el-table-column> -->

                  <el-table-column type="selection" width="50">
                  </el-table-column>

                  <el-table-column :show-overflow-tooltip="true" prop="orderNo" label="订单编号" min-width="150" ></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="userName" label="学员姓名" min-width="150" ></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="mobilePhone" label="手机号" min-width="150" ></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="goodsName" label="报读商品" min-width="150" ></el-table-column>

              </el-table>
          </div>
      </div>

      <div class="page_test_bottom">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="pageParam.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageParam.pageSize"
          :total="total"
        />
      </div>
      
      <div slot="footer">
        <el-button @click="onCancel">返回</el-button>
        <el-button type="primary" @click="onOk" :disabled="!isCurrentRow" >确认</el-button>
      </div>
    </HqDrawer>

  </span>
</template>
<script>

import { getOrderListPage } from "api/order/change";

export default {
  name: "positionProfile",
  components: {
  },
  props: {

  },
  data() {
    return {
      drawerStudent: false,
      listLoading: false,
      studentName:'',
      mobile:'',
      orderNo:'',
      
      tableData:[],
      // pageParam: {
      //   pageNum: 1,
      //   pageSize: 10
      // },
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        sortItems: [
          {
            asc: false,
            column: "createTime",
          },
        ],
      },
      total: 0,

      currentRow: {},
    };
  },
  computed: {
    isCurrentRow() {
      if (!this.currentRow) return false
      return !!Object.keys(this.currentRow).length
    },
    tableHeight() {
      return window.outerHeight - 420 + 'px'
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    init(row) {
      this.$refs.drawer.init()
    },
    // 查询
    search() {
      this.GetList()
    },
    // 重置
    reset() {
      this.studentName = ''
      this.mobile = ''
      this.orderNo = ''
      this.GetList()
    },
    // 获取表格数据
    async GetList() {
      let params = {
        // curPage: this.pageParam.pageNum,
        // pageSize: this.pageParam.pageSize,
        name: this.studentName,
        mobile: this.mobile,
        orderNo: this.orderNo,
        ...this.pageParam
      }
      const { code, result, msg} = await getOrderListPage(params)
      this.listLoading = true
      if (code == 0) {
        this.listLoading = false
        this.tableData = result.list
        this.total = result.total
      } else {
        this.listLoading = false
        this.$message.error(msg)
      }
    },
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.GetList()
    },
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.GetList()
    },
    // 点击行事件
    onCurrentChange(row) {
      // this.currentRow = row
      // console.log(111111111,row)
      // console.log(this.currentRow)
    },
    // 处理行点击
    handleRowClick(row) {
      console.log(2222222,row)
      if (this.currentRow?.id === row.id) {
        // 点击已选中行时取消选择
        this.$refs.singleTable.toggleRowSelection(null)
        this.currentRow = null
      } else {
        // 设置新选中行
        this.$refs.singleTable.toggleRowSelection(row)
      }
    },
    handleSelectionChange(val) {
      if (val.length > 1) {
      this.$refs.singleTable.clearSelection(); //清空列表的选中
      this.$refs.singleTable.toggleRowSelection(val[val.length-1]); //只显示选中最后一个 这时val还是多选的列表(就是你选中的几个数据)
      } else if (val.length === 1) {
        this.currentRow = val[val.length-1];
      } else {
        this.currentRow = []; //this.multipleTable是选中行的最后一条数据
      }
      console.log(this.currentRow)
    },

    // 取消
    onCancel() {
      this.$refs.drawer.close()
      this.$emit("update:visible", false)
    },
    onOk() {
      let row = JSON.parse(JSON.stringify(this.currentRow))
      console.log('row', row)
      this.$refs.drawer.close()
      this.$emit("update:visible", false)
      this.$emit("on-ok", row)
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 26px !important;
}
::v-deep .el-form-item__error {
  padding-left: 10px !important;
}
</style>
<style scoped lang="scss">
  .searchBtn{
    margin-bottom: 6px;

    .el-button {
      padding:0px 18px 0px 18px;
      line-height: 32px;
      height: 30px;
    }
  }
   [v-cloak] {
            display: none;
    }
    #app{
        background: #ffffff;
    }
    .iconxieyiguanli{
        color: #9bacbf;
        margin-right: 8px;
    }
    .ty-try-header{
        // padding: 24px 30px 24px 48px;
    }
    .ty-try-header p:nth-child(1) span:nth-child(1){
        font-size: 14px;
        font-weight: 700;
        color: #666666;
    }
    .ty-try-header p:nth-child(1) span:nth-child(2){
        float: right;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
    }
    .ty-try-header p:nth-child(2){
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #999999;
        padding: 7px 0 0 22px;
    }

    .ty-drawer-content {
      width: 94%;
      margin: 0 auto;
    }

    v-deep .el-drawer__header {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #999;
    }

    .ty-drawer-footer {
      position: absolute;
      bottom: 24px;
      left: 0;
      text-align: center;
      width: 100%;
    }
   .ty-drawer-content ::v-deep.el-table__header-wrapper .el-table__header .el-checkbox {
        display: none;
      }
</style>
