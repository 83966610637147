<template>
  <span>
    <el-button
      type="primary"
      class="hq-button"
      icon="el-icon-files"
      @click="addClick"
      >职位档案</el-button
    >
    
    <HqDrawer ref="drawer" :visible="addShow" title="职位档案" size="90%" @on-cancel="cancel">
      <div class="ty-drawer-content">
          <div class="ty-try-header">
            <el-row :gutter="24" style="margin-top: 18px">
              
                <el-col :span="4">
                  <v-text-field
                    v-model="jobName"
                    label="职位名称"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                  ></v-text-field>
                </el-col>

                <el-col :span="8" class="searchBtn">
                  <el-button size="small" type="primary" @click="search">查询</el-button>
                  <el-button size="small" @click="reset">重置</el-button>
                  <el-button size="small" type="primary" icon="el-icon-folder-add" @click="openAdd()">新增</el-button>
                </el-col>

            </el-row>
            </div>


          <div class="ty-page-list ty-drawer-list">
              <el-table
                      border
                      stripe
                      size="mini"
                      element-loading-text="拼命加载中"
                      :header-cell-style="{ background: '#e5f2ff' }"
                      style="width: 100%;margin:0 0 10px;border:1px solid #E1E4EB;"
                      highlight-current-row
                      max-height='700px'
                      :data="contentList"
                      default-expand-all
                      row-key="teacherContentId"
                      v-loading="listLoading"
                      :tree-props="{children: 'children'}">
                  <el-table-column :show-overflow-tooltip="true"  fixed prop="id" label="职位编码" type="" width="150" >
                    <template slot-scope="scope">
                      {{ (scope.row.id.length==1 ? 'ZW000' :(scope.row.id.length==2 ? 'ZW00' : 'ZW0') )+ scope.row.id }}
                    </template>
                  </el-table-column>
                  <el-table-column :show-overflow-tooltip="true"  fixed prop="name" label="职位名称" min-width="150" ></el-table-column>
                  <el-table-column :show-overflow-tooltip="true"  prop="remark" label="备注" min-width="150" ></el-table-column>
                  <el-table-column :show-overflow-tooltip="true"  prop="createId" label="创建人" min-width="150" ></el-table-column>
                  <el-table-column :show-overflow-tooltip="true"  prop="createTime" label="创建时间" min-width="150" ></el-table-column>
                  <el-table-column :show-overflow-tooltip="true"  prop="updateId" label="更新人" min-width="150" ></el-table-column>
                  <el-table-column :show-overflow-tooltip="true"  prop="updateTime" label="更新时间" min-width="150" ></el-table-column>
                  <el-table-column
                    label="操作"
                    prop=""
                    width="150"
                    fixed="right"
                  >
                    <template slot-scope="scope">
                      <el-button type="text" @click="openEdit(scope.row)">编辑</el-button>
                      <el-button type="text" @click="deleteClick(scope.row, 0)">删除</el-button>  
                    </template>
                  </el-table-column>
              </el-table>
          </div>
      </div>

      <div class="page_test_bottom">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="pageParam.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageParam.pageSize"
          :total="total"
        />
      </div>
      
      <div slot="footer">
        <el-button @click="cancel" type="primary">返回</el-button>
      </div>
    </HqDrawer>

    <el-dialog
      :title="addEditTitle"
      :visible.sync="showAddEdit"
      :close-on-click-modal="false"
      left
      width="30%">
      
      <el-form 
        ref="editForm" 
        :model="editForm" 
        :rules="invalidRules" 
        label-width="80px"
        label-position="right">
        <el-form-item label="职位编码" v-if="operationType === 1" prop="code">
          <el-input
            v-model="editForm.code"
            maxlength="15"
            :disabled="true"
            >
          </el-input>
        </el-form-item>

        <el-form-item label="职业名称" prop="name">
          <el-input
            v-model="editForm.name"
            maxlength="15"
            placeholder="请输入职业名称"
            >
          </el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input 
              v-model="editForm.remark" 	
              :rows="3"
              type="textarea"
              placeholder="备注，30 字内"
              maxlength="30"
              show-word-limit
              >
            </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddEdit">取 消</el-button>
        <el-button type="primary" @click="saveAddEdit" :loading="saveBtnLoading">确 定</el-button>
      </span>
    </el-dialog>
  </span>
</template>
<script>
import { getEmploymentPositionList, employmentPositionAdd, employmentPositionUpdate } from "@/api/count";
import { formatTimes } from "@/utils/timeDate";
export default {
  name: "positionProfile",
  components: {
  },
  props: {

  },
  data() {
    return {
      listLoading:false,
      showAddEdit:false,
      operationType:0,
      invalidRules: {
        name: [{ required: true, message: "请填写职位名称" }],
        remark: [{ message: "请填写备注", trigger: "blur" }]
      },
      jobName:'',
      addShow: false,
      // 新增、编辑字段
      editForm: {
        id:'',
        name:'',
        remark: '',
      },
      contentList:[],
      pageParam: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      saveBtnLoading: false,//保存按钮是否加载
    };
  },
  computed: {
    addEditTitle() {
      return this.operationType === 0 ? "新增" : "编辑";
    },
    
  },
  mounted() {
    this.search()
  },
  methods: {
    addClick() {
      this.$refs.drawer && this.$refs.drawer.init()
      this.reset()
    },
    // 打开新增弹窗
    openAdd(){
      this.operationType = 0;
      this.showAddEdit = !this.showAddEdit;
      this.editForm = {
        id:'',//校区
        code: '',//分成类型
        name: '', // 开始日期
        reamrk: '',   // 结束日期
      }
      this.resetForm("editForm");
    },
    // 打开编辑弹窗
    openEdit(val) {
      this.editForm.id = val.id
      this.editForm.code = (val.id.length==1 ? 'ZW000' :(val.id.length==2 ? 'ZW00' : 'ZW0') )+ val.id
      this.editForm.name = val.name
      this.editForm.remark = val.remark
      this.editForm.isExist = 0

      this.operationType = 1;
      this.showAddEdit = !this.showAddEdit;
    },
    // 新增/修改弹窗 -- 保存btn操作 --
    saveAddEdit() {
      if (this.operationType === 0) {// 新增
        this.doAdd();
      } else if (this.operationType === 1) {//编辑
        this.doEdit();
      }
    },
    async doAdd(){
      this.editForm.createId = this.$store.state.user.loginUser.userId
      const { code, msg} = await employmentPositionAdd(this.editForm)

      if (code == 0) {
        this.$message.success('新增成功');
        this.showAddEdit = !this.showAddEdit;
        this.saveBtnLoading = false;
        this.search();
        this.resetForm("editForm");

      } else {
        this.saveBtnLoading = false;
        this.$message.warning(msg);
      }
    },
    async doEdit(){
      this.editForm.updateId = this.$store.state.user.loginUser.userId
      this.editForm.updateTime= formatTimes(new Date())
      const { code,  msg} = await employmentPositionUpdate(this.editForm)

      if (code == 0) {
        this.$message.success('修改成功');
        this.showAddEdit = !this.showAddEdit;
        this.saveBtnLoading = false;
        this.search();
        this.resetForm("editForm");

      } else {
        this.saveBtnLoading = false;
        this.$message.warning(msg);
      }
    },
    // 取消操作--
    cancelAddEdit() {
      this.resetForm("editForm");
      this.showAddEdit = false;
    },
    // 重置表单--
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 查询
    search() {
      this.GetList()
    },
    // 重置
    reset() {
      this.jobName = ''
      this.GetList()
    },
    // 取消
    cancel() {
      this.addShow = false;
      this.$refs.drawer.close()
    },
    // 删除
    deleteClick(val) {
      let params = {
        id: val.id,
        isExist: 1,
        updateId: this.$store.state.user.loginUser.userId,
        updateTime: formatTimes(new Date())
      }
      this.$hqMessageBox({
      message:
        `确认删除 ${val.name} 职位？`,
      })
      .then(() => {
        return new Promise(async (resolve, reject) => { 
          const { code,  msg} = await employmentPositionUpdate(params)

          if (code == 0) {
            this.$message.success("删除成功！");
            this.search();

          } else {
            this.$message.warning(msg);
          }
        })
      })
      .catch(() => {
        console.log("取消");
      })
    },

    // 获取表格数据
    async GetList() {
      let params = {
        page: this.pageParam.pageNum,
        limit: this.pageParam.pageSize,
        code: '',
        name: this.jobName,
        remark: '',
      }
      const { code, result, msg} = await getEmploymentPositionList(params)
      this.listLoading = true
      if (code == 0) {
        this.listLoading = false
        this.contentList = result.records
        this.total = result.total
      } else {
        this.listLoading = false
        this.$message.error(msg)
      }
    },
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.GetList()
    },
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.GetList()
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 26px !important;
}
::v-deep .el-form-item__error {
  padding-left: 10px !important;
}
</style>
<style scoped lang="scss">
  .searchBtn{
    margin-bottom: 6px;

    .el-button {
      padding:0px 18px 0px 18px;
      line-height: 32px;
      height: 30px;
    }
  }
   [v-cloak] {
            display: none;
    }
    #app{
        background: #ffffff;
    }
    .iconxieyiguanli{
        color: #9bacbf;
        margin-right: 8px;
    }
    .ty-try-header{
        // padding: 24px 30px 24px 48px;
    }
    .ty-try-header p:nth-child(1) span:nth-child(1){
        font-size: 14px;
        font-weight: 700;
        color: #666666;
    }
    .ty-try-header p:nth-child(1) span:nth-child(2){
        float: right;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
    }
    .ty-try-header p:nth-child(2){
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #999999;
        padding: 7px 0 0 22px;
    }

    .ty-drawer-content {
      width: 94%;
      margin: 0 auto;
    }

    v-deep .el-drawer__header {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #999;
    }

    .ty-drawer-footer {
      position: absolute;
      bottom: 24px;
      left: 0;
      text-align: center;
      width: 100%;
    }
</style>
