<template>
  <span>
    <el-button type="primary" class="hq-button" @click="exportModel = true" icon="el-icon-download">导出</el-button>
    <!-- 导出 -->
    <HqModal :visible="exportModel" title="导出" @on-cancel="cancel">
      <div style="text-align: center">{{ message }}</div>
      <template #footer>
        <el-button
          v-if="!down"
          type="primary"
          @click="exportCustomer()"
          :loading="exportloading"
          >确定</el-button
        >
        <a :href="url" v-else>
          <el-button
            type="success"
            @click="cancel"
            >点击下载</el-button
          >
        </a>
        <el-button
          type="error"
          @click="cancel"
          style="margin-left: 8px"
          >取消</el-button
        >
      </template>
    </HqModal>
  </span>
</template>
<script>
import { doOrderExport } from "api/order/change";
import { exportEmploymentInformation } from "@/api/count";
export default {
  name: "export",
  props: {
    pageParam: {
      type: Object,
    },
    searchParam: {
      type: Object,
    },
    total: {
      type: Number,
    },
  },
  data() {
    return {
      exportModel: false,
      exportloading: false,
      down: false,
      url: "",
      message: "您要将这些数据导出吗？"
    }
  },
  methods: {
    // 导出
    async exportCustomer() {
      this.exportloading = true
      this.message = "正在为你打包数据，请稍等！"
      const res = await exportEmploymentInformation({...this.pageParam, ...this.searchParam, ...{total: this.total}})
      if(res.code === 0) {
        this.message = "数据已经打包完毕，请点击下载！"
        this.url = res.result
        this.exportloading = false
        this.down = true
      } else {
        this.exportloading = false
        this.$message.error(res.msg)
        this.exportModel = false
      }
    },
    cancel() {
      this.exportModel = false
      this.exportloading = false
      this.down = false
      this.url = ""
      this.message = "您要将这些数据导出吗？"
    }
  },
};
</script>
