var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "HqDrawer",
        {
          ref: "drawer",
          attrs: {
            title:
              _vm.status == "add"
                ? "新增"
                : _vm.status == "edit"
                ? "编辑"
                : "查看详情",
            visible: _vm.drawerVisible,
            size: "70%"
          },
          on: {
            "update:visible": function($event) {
              _vm.drawerVisible = $event
            },
            "on-cancel": _vm.cancel
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "90%",
                margin: "0 auto",
                padding: "12px 0 40px 0"
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, rules: _vm.addruleValidate }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("p", { staticClass: "section_title" }, [
                          _vm._v("学员信息")
                        ])
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "orderNo" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "学员订单号",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.form.orderNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "orderNo", $$v)
                                  },
                                  expression: "form.orderNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "studentName" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "学员姓名",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  readonly: "",
                                  "suffix-icon": "el-icon-search",
                                  disabled: _vm.status != "add"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onOpenChoiceStudent.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.form.studentName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "studentName", $$v)
                                  },
                                  expression: "form.studentName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("StudentOrder", {
                        ref: "studentOrderModalRef",
                        attrs: { visible: _vm.showStudentOrderModal },
                        on: {
                          "on-ok": _vm.onGoodsDrawerOk,
                          "update:visible": function($event) {
                            _vm.showStudentOrderModal = $event
                          }
                        }
                      }),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "sex" } },
                            [
                              _c("dict-select", {
                                attrs: { code: "SEX" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return _c("v-select", {
                                        attrs: {
                                          items: props.dictData,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "性别",
                                          "item-text": "label",
                                          "item-value": "label",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto",
                                          disabled: _vm.status == "detail"
                                        },
                                        model: {
                                          value: _vm.form.sex,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "sex", $$v)
                                          },
                                          expression: "form.sex"
                                        }
                                      })
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "mobilePhone" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "学员手机号",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.form.mobilePhone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "mobilePhone", $$v)
                                  },
                                  expression: "form.mobilePhone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "idcard" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "学员身份证号",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.form.idcard,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "idcard", $$v)
                                  },
                                  expression: "form.idcard"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "educationName" } },
                            [
                              _c("dict-select", {
                                attrs: { code: "EDUCATION_TYPE" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return _c("v-select", {
                                        attrs: {
                                          items: props.dictData,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "学历",
                                          "item-text": "label",
                                          "item-value": "label",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto",
                                          disabled: ""
                                        },
                                        model: {
                                          value: _vm.form.educationName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "educationName",
                                              $$v
                                            )
                                          },
                                          expression: "form.educationName"
                                        }
                                      })
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "subscribeSchoolName" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "成交校区",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.form.subscribeSchoolName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "subscribeSchoolName",
                                      $$v
                                    )
                                  },
                                  expression: "form.subscribeSchoolName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "44px" },
                              attrs: { prop: "goodsName" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "报读商品",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.form.goodsName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "goodsName", $$v)
                                  },
                                  expression: "form.goodsName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("p", { staticClass: "section_title" }, [
                          _vm._v("就业确认信息")
                        ])
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "interviewPosition" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.jobNameList,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "面试职位",
                                  "item-text": "name",
                                  "item-value": "name",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto",
                                  "no-data-text": "暂无数据",
                                  disabled: _vm.status == "detail"
                                },
                                on: { change: _vm.changeJobName },
                                model: {
                                  value: _vm.form.interviewPosition,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "interviewPosition", $$v)
                                  },
                                  expression: "form.interviewPosition"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "44px" },
                              attrs: { prop: "employmentCompany" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "就业企业",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  disabled: _vm.status == "detail"
                                },
                                model: {
                                  value: _vm.form.employmentCompany,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "employmentCompany", $$v)
                                  },
                                  expression: "form.employmentCompany"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "44px" },
                              attrs: { prop: "monthlyPay" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "录用月薪",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  disabled: _vm.status == "detail"
                                },
                                model: {
                                  value: _vm.form.monthlyPay,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "monthlyPay", $$v)
                                  },
                                  expression: "form.monthlyPay"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "employmentCtiy" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.cityList,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "就业城市",
                                  "item-text": "name",
                                  "item-value": "name",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto",
                                  "no-data-text": "暂无数据",
                                  disabled: _vm.status == "detail"
                                },
                                on: { change: _vm.changeCity },
                                model: {
                                  value: _vm.form.employmentCtiy,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "employmentCtiy", $$v)
                                  },
                                  expression: "form.employmentCtiy"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "employmentState" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.employmentStateList,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "就业状态",
                                  "item-text": "name",
                                  "item-value": "name",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto",
                                  "no-data-text": "暂无数据",
                                  disabled: _vm.status == "detail"
                                },
                                on: { change: _vm.changeEmploymentState },
                                model: {
                                  value: _vm.form.employmentState,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "employmentState", $$v)
                                  },
                                  expression: "form.employmentState"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "employmentTeacherId" } },
                            [
                              _c("teacher-select", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return _c("v-select", {
                                        attrs: {
                                          items: props.teacherData,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "就业老师",
                                          "item-text": "userName",
                                          "item-value": "userName",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto",
                                          disabled: _vm.status == "detail"
                                        },
                                        model: {
                                          value: _vm.form.employmentTeacher,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "employmentTeacher",
                                              $$v
                                            )
                                          },
                                          expression: "form.employmentTeacher"
                                        }
                                      })
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "admissionsTeacherId" } },
                            [
                              _c("teacher-select", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return _c("v-select", {
                                        attrs: {
                                          items: props.teacherData,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "招生老师",
                                          "item-text": "userName",
                                          "item-value": "userName",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto",
                                          disabled: ""
                                        },
                                        model: {
                                          value: _vm.form.admissionsTeacher,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "admissionsTeacher",
                                              $$v
                                            )
                                          },
                                          expression: "form.admissionsTeacher"
                                        }
                                      })
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "remark" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  height: 80,
                                  name: "input-7-4",
                                  label: "备注",
                                  value: ""
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }