<template>
  <span>
    <HqDrawer
      :title="status == 'add'?'新增':(status == 'edit' ? '编辑' :'查看详情')"
      :visible.sync="drawerVisible"
      size="70%"
      ref="drawer"
      @on-cancel="cancel"
    >
      <div style="width: 90%;margin: 0 auto;padding: 12px 0 40px 0">
        <el-form ref="form" :model="form" :rules="addruleValidate">

          <el-row :gutter="24">
            <el-col :span="24">
              <p class="section_title">学员信息</p>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="orderNo">
                <v-text-field
                  v-model="form.orderNo"
                  label="学员订单号"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  disabled
                ></v-text-field>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="studentName">
                <v-text-field
                  v-model="form.studentName"
                  label="学员姓名"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  readonly
                  @click.native="onOpenChoiceStudent"
                  
                  suffix-icon="el-icon-search"
                  :disabled="status != 'add'"
                ></v-text-field>
              </el-form-item>
            </el-col>

            <StudentOrder ref="studentOrderModalRef" @on-ok="onGoodsDrawerOk" :visible.sync="showStudentOrderModal" />

            <el-col :span="12">
              <el-form-item prop="sex">
                <dict-select code="SEX">
                  <v-select
                    v-model="form.sex"
                    slot-scope="props"
                    :items="props.dictData"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="性别"
                    item-text="label"
                    item-value="label"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    :disabled="status == 'detail'"
                  ></v-select>
                </dict-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="mobilePhone">
                <v-text-field
                  v-model="form.mobilePhone"
                  label="学员手机号"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  disabled
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="idcard">
                <v-text-field
                  v-model="form.idcard"
                  label="学员身份证号"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  disabled
                ></v-text-field>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="educationName">
                <dict-select code="EDUCATION_TYPE">
                  <v-select
                    v-model="form.educationName"
                    slot-scope="props"
                    :items="props.dictData"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="学历"
                    item-text="label"
                    item-value="label"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    disabled
                  ></v-select>
                </dict-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="subscribeSchoolName">
                <v-text-field
                  v-model="form.subscribeSchoolName"
                  label="成交校区"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  disabled
                ></v-text-field>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="goodsName" style="margin-bottom: 44px">
                <v-text-field
                  v-model="form.goodsName"
                  label="报读商品"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  disabled
                ></v-text-field>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="24">
              <p class="section_title">就业确认信息</p>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="interviewPosition">
                <v-autocomplete
                  v-model="form.interviewPosition"
                  :items="jobNameList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="面试职位"
                  item-text="name"
                  item-value="name"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  @change="changeJobName"
                  no-data-text="暂无数据"
                  :disabled="status == 'detail'"
                ></v-autocomplete>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="employmentCompany" style="margin-bottom: 44px">
                <v-text-field
                  v-model="form.employmentCompany"
                  label="就业企业"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  :disabled="status == 'detail'"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="monthlyPay" style="margin-bottom: 44px">
                <v-text-field
                  v-model="form.monthlyPay"
                  label="录用月薪"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  :disabled="status == 'detail'"
                ></v-text-field>
              </el-form-item>
            </el-col>



            <el-col :span="12">
              <el-form-item prop="employmentCtiy">
                <v-autocomplete
                  v-model="form.employmentCtiy"
                  :items="cityList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="就业城市"
                  item-text="name"
                  item-value="name"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  @change="changeCity"
                  no-data-text="暂无数据"
                  :disabled="status == 'detail'"
                ></v-autocomplete>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="employmentState">
                <v-autocomplete
                  v-model="form.employmentState"
                  :items="employmentStateList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="就业状态"
                  item-text="name"
                  item-value="name"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  @change="changeEmploymentState"
                  no-data-text="暂无数据"
                  :disabled="status == 'detail'"
                ></v-autocomplete>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="employmentTeacherId">
                <!-- <v-autocomplete
                  v-model="form.employmentTeacherId"
                  :items="teacherList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="就业老师"
                  item-text="userName"
                  item-value="userId"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  @change="changeEmploymentTeacher"
                  no-data-text="暂无数据"
                ></v-autocomplete> -->

                <teacher-select>
                  <v-select
                    v-model="form.employmentTeacher"
                    slot-scope="props"
                    :items="props.teacherData"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="就业老师"
                    item-text="userName"
                    item-value="userName"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    :disabled="status == 'detail'"
                  ></v-select>
                </teacher-select>
                
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="admissionsTeacherId">

                <teacher-select>
                  <v-select
                    v-model="form.admissionsTeacher"
                    slot-scope="props"
                    :items="props.teacherData"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="招生老师"
                    item-text="userName"
                    item-value="userName"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    disabled
                  ></v-select>
                </teacher-select>

                <!-- <v-autocomplete
                  v-model="form.admissionsTeacherId"
                  :items="teacherList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="招生老师"
                  item-text="userName"
                  item-value="userId"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  @change="changeAdmissionsTeacher"
                  no-data-text="暂无数据"
                ></v-autocomplete> -->

              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item prop="remark">
                <v-textarea
                  v-model="form.remark"
                  outlined
                  :height="80"
                  name="input-7-4"
                  label="备注"
                  value=""
                ></v-textarea>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" type="primary">确认</el-button>
      </div>
    </HqDrawer>
  </span>
</template>
<script>
import dictSelect from "@/components/dictSelect";
import teacherSelect from "./teacherSelect";
import StudentOrder from "./modelStudentOrder";
import { getResTeachList } from "api/public";

import { doJiamiSeePhone } from "api/customer";
import Strhide from "@/utils/strhide";
import Crypto from "@/utils/crypto";

import { getCityList } from "@/api/count";

import { getEmploymentPositionList, employmentInformationAdd, employmentInformationUpdate } from "@/api/count";

import { formatTimes } from "@/utils/timeDate";
export default {
  name: "addEditDetail",
  components: {
    dictSelect,
    teacherSelect,
    StudentOrder
  },
  props: {
    method: {
      type: Function
    }
  },
  data() {
    return {
      status: 'add',
      noMingwenPhone:'',
      drawerVisible: false,
      showStudentOrderModal: false,

      form: {
        orderNo: '',
        studentName: '',
        sex: '',
        mobilePhone: '',
        idcard: '',
        education: '',//新加
        educationName: '',
        subscribeSchoolName: '',
        goodsName: '',


        interviewPosition: '',
        employmentCompany: '',
        monthlyPay: '',
        employmentCtiy: '',
        employmentState: '',
        employmentTeacher: '',
        employmentTeacherId: '',

        admissionsTeacher: '',
        admissionsTeacherId: '',
        remark: '',
      },

      cityList:[],
      jobNameList:[],
      teacherList:[],//老师列表
      employmentStateList:[
        {id: 1, name: '就业成功'},
        {id: 2, name: '培训中'},
        {id: 3, name: '待业中'},
      ],
      
      addruleValidate: {
        studentName: [
          { message: '请选择学员', required: true }
        ],
        employmentState: [
          { message: '请选择就业状态', required: true }
        ],        
      },


    };
  },
  computed: {

  },
  mounted() {
    this.getAllList()
  },
  methods: {
    changeJobName(value) {
      this.jobNameList.map(item => {
        if(item.id === value) {
          this.form.interviewPosition = item.id
          // this.form.teacherPk = item.ncUserPk
        }
      })
    },
    changeCity(value){
      this.cityList.map(item => {
        if(item.name === value) {
          this.form.employmentCtiy = item.name
        }
      })
    },
    changeEmploymentState(value){
      this.employmentStateList.map(item => {
        if(item.name === value) {
          this.form.employmentState = item.name
        }
      })
      this.$refs['form'].validate()
      
    },
    changeAdmissionsTeacher(value) {// 招生老师
      // console.log('招生老师',value)
      // this.$forceUpdate();
      this.teacherList.map(item => {
        if(item.userId === value) {
          this.form.admissionsTeacherId = item.id
          this.form.admissionsTeacher = item.userName
        }
      })
    },
    changeEmploymentTeacher(value) {// 就业老师
      // console.log('就业老师',value)
      this.teacherList.map(item => {
        if(item.userId === value) {
          this.form.employmentTeacherId = item.id
          this.form.employmentTeacher = item.userName
        }
      })
    },
    // 获取一开始的下拉框来源
    getAllList(){
      this.getTeacherList()
      this.getCityList()
      this.getEmploymentPositionList()
    },
    // 获取老师 -下拉框来源
    async getTeacherList() {
      const res = await getResTeachList()
      this.teacherList = res.result
    },
    // 获取城市 -下拉框来源
    async getCityList() {
      const resCity = await getCityList({name:'',limit:999})
      if(resCity.code == 0){
        this.cityList = resCity.result.records
      }
    },
    // 获取面试职位 -下拉框来源
    async getEmploymentPositionList() {
      let params = {
        curPage: 1,
        pageSize: 100,
        code: '',
        name: '',
        remark: '',
      }
      const { code, result} = await getEmploymentPositionList(params)
      if (code == 0) {
        this.jobNameList = result.records
      } 
    },

    onOpenChoiceStudent(row){
      this.showStudentOrderModal = true;
      this.$refs.studentOrderModalRef.init(row)
    },
     // 选择学员弹窗完毕
     async onGoodsDrawerOk(val) {
      console.log('[选中学员]', val);

      this.form.orderNo = val.orderNo
      this.form.studentName = val.userName // 学员姓名
      this.form.mobilePhone = val.mobilePhone
      this.form.idcard = val.idCard
      this.form.educationName = val.educationName // 学历名字
      this.form.education = val.education // 学历[新增 ]id
      this.form.subscribeSchoolName = val.subscribeSchoolName
      this.form.goodsName = val.goodsName
      this.form.admissionsTeacher = val.teacherRecruitName //招生老师
      this.form.admissionsTeacherId = val.teacherRecruitId //招生老师id
      // 显示非明文手机号
      let str = val.customerId.toString()
      let res = await doJiamiSeePhone(Crypto.encrypt(Strhide.getKeyHide(),Strhide.getIvHide(), str))
      this.noMingwenPhone = res.result
      // this.form.mobilePhone = this.noMingwenPhone
      this.$refs['form'].validate()
    },
    // 编辑初始化
    init(status,row) {
      this.$refs.drawer.init()
      this.status = status// 'add''edit''detail'
      if(status != 'add'){
        this.row = row
        this.form = row
      }else if(status === 'add'){
        this.form = {
          orderNo: '',
          studentName: '',
          sex: '',
          mobilePhone: '',
          idcard: '',
          education: '',//新加
          educationName: '',
          subscribeSchoolName: '',
          goodsName: '',


          interviewPosition: '',
          employmentCompany: '',
          monthlyPay: '',
          employmentCtiy: '',
          employmentState: '',
          employmentTeacher: '',
          employmentTeacherId: '',

          admissionsTeacher: '',
          admissionsTeacherId: '',
          remark: '',
        }
      }
    },

    // 保存
    save() {
      this.$refs['form'].validate((valid) => {
        if(valid) {
          if(this.status ==='add'){
            // 存储明文
            this.form.mobilePhone = this.noMingwenPhone
            this.doAdd()
          }else if(this.status ==='edit'){
            delete this.form.mobilePhone// 编辑时不再传参手机号，编辑时为加密手机号，传参后会覆盖数据库原来的明文手机号
            delete this.form.createId// 编辑时不传createId，因为新增时，传id后端把id转化为人名，重新编辑保存会报错
            this.doEdit()
          }
        }
      })
    },
    async doAdd(){
      this.form.createId = this.$store.state.user.loginUser.userId
      const { code, msg} = await employmentInformationAdd(this.form)
      if (code == 0) {
        this.$message.success('新增成功');
        this.method()// 刷新外层列表
        this.cancel()
      } else {
        this.$message.warning(msg);
        this.cancel()
      }
    },
    async doEdit(){
      this.form.updateId = this.$store.state.user.loginUser.userId
      this.form.updateTime = formatTimes(new Date())
      this.form.isExist = 0
      const { code, msg} = await employmentInformationUpdate(this.form)
      if (code == 0) {
        this.$message.success('修改成功');
        this.method()// 刷新外层列表
        this.cancel()
      } else {
        this.$message.warning(msg);
        this.cancel()
      }
    },

    // 取消
    cancel() {
      this.showStudentOrderModal = false;
      this.form = {
        orderNo: '',
        studentName: '',
        sex: '',
        mobilePhone: '',
        idcard: '',
        education: '',//新加
        educationName: '',
        subscribeSchoolName: '',
        goodsName: '',


        interviewPosition: '',
        employmentCompany: '',
        monthlyPay: '',
        employmentCtiy: '',
        employmentState: '',
        employmentTeacher: '',
        employmentTeacherId: '',

        admissionsTeacher: '',
        admissionsTeacherId: '',
        remark: '',
      }
      this.$refs.drawer.close()
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 26px !important;
}
::v-deep .el-form-item__error {
  padding-left: 10px !important;
}
.section_title{
  font-weight: bold;
  font-size: 15px;
}
</style>
