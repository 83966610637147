<template>
  <div class="customer">
    <div ref="contentTop">
      <div class="tool">
        <div class="customer-tool">
            <div></div>
            
            <div style="line-height: 40px">

              <!-- 新增 -->
              <!-- <Add :method="GetSearchList" /> -->
              <AddEditDetail ref="drawer" :method="GetSearchList" :visible.sync="showAddEditDetail" />
              <el-button type="primary" class="hq-button" icon="el-icon-folder-add" @click="openAddEditDetail('add')">新增</el-button>

              <Export :pageParam="pageParam" :total="total" :searchParam="searchParam" />

              <!-- 职位档案-->
              <PositionProfile :visible.sync="showPositionProfileModal" />
            </div>
        </div>
      </div>
      <div
        :gutter="10"
        style="width: 94%; margin: 0 auto"
      >
          <Search
            :searchAllParam="searchAllParam"
            @search="search"
            @reset="reset"
            :searchParamList="searchParamList"
            code="count"
          />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @row-click="clickRow"
        >
          <el-table-column :show-overflow-tooltip="true"
          fixed
            label="订单号"
            prop="orderNo"
            width="180"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
          fixed
            label="学员姓名"
            prop="studentName"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="性别"
            prop="sex"
            width="100"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="报读商品"
            prop="goodsName"
            width="160"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员手机号"
            prop="mobilePhone"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员身份证号"
            prop="idcard"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学历"
            prop="educationName"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="成交校区"
            prop="subscribeSchoolName"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="面试职位"
            prop="interviewPosition"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="就业企业"
            prop="employmentCompany"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="录用月薪"
            prop="monthlyPay"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="就业城市"
            prop="employmentCtiy"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="就业状态"
            prop="employmentState"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="就业老师"
            prop="employmentTeacher"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="招生老师"
            prop="admissionsTeacher"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="备注"
            prop="remark"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建人"
            prop="createId"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建时间"
            prop="createTime"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="更新人"
            prop="updateId"
            width="150"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="更新时间"
            prop="updateTime"
            width="150"
          ></el-table-column>
          
          <el-table-column
            label="操作"
            prop=""
            width="220"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openAddEditDetail('edit',scope.row)">编辑</el-button>
              <el-button type="text" @click="openAddEditDetail('detail',scope.row)">详情</el-button>  
              <el-button type="text" @click="deleteClick(scope.row, 0)">删除</el-button>  
            </template>
          </el-table-column>


          
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
  
  </div>
</template>

<script>
// import Search from "@/components/search";
import { mapGetters } from "vuex";
import common from '@/views/order/after-sale-nc/mixins/common'
import Export from "./components/studentEmployment/export";
import Search from "@/components/search";
import PositionProfile from "./components/studentEmployment/modelPositionProfile";
import AddEditDetail from "./components/studentEmployment/modelAddEditDetail";
import { getEmploymentInformationList, employmentInformationUpdate } from "@/api/count";
import { formatTimes } from "@/utils/timeDate";

import { getDeptList } from "api/public";


export default {
  name: 'costmanagement',
  mixins: [common],
  components: {
      Search,
      PositionProfile,
      Export,
      AddEditDetail,
  },
  data() {
    return {
      showPositionProfileModal:false,// 打开职位档案开关
      showAddEditDetail: false,
        saveModel: false,
        searchAllParam: [
          { name: "orderNo", label: "订单号", type: "input", value: ""},
          
          { name: "mobilePhone", label: "手机", type: "input", value: "" },
          { name: "studentName", label: "学员姓名", type: "input", value: ""},
          {
            name: "subscribeSchoolIds",
            label: "成交校区",
            type: "multiTreeLabel",
            option: [],
            value: "",
          },
        ],
        searchParamList: [],
        searchParam: {},
        listLoading: false,
        tableData: [],
        pageParam: {
            pageNum: 1,
            pageSize: 10
        },
        total: 0,
        publicPath: process.env.BASE_URL,
        fileList: [],   // excel文件列表
    }
  },
  methods: {
    // 获取一开始的下拉框来源
    async _getSearchAll() {
      this.searchAllParam.map((item) => {
        if(item.name == 'subscribeSchoolIds') {
          getDeptList().then(res => item.option = res.result)
        }
      })
    },
    // 查询btn
    search() {
      this.searchParam = {}
      this.searchAllParam.forEach( item => {
      if( (item.value !== undefined && item.value !== null && item.value !== '' ) || ( Array.isArray(item.value)  && item.value.length ) ) {
          if(item.type === "date") {
            if(item.name === "createTime") {
              // console.log('item.value[0]', item.value[0]);
              this.searchParam.createTimeBegin = item.value[0];
              this.searchParam.createTimeEnd = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value 
          }
          }
        })
      this.GetSearchList()
    },
    // 重置btn
    reset() {
      this.searchAllParam.map(item => {
        if(item.value || item.value == 0) {
          if(item.name === "createTime") {
            item.value = []
          } else {
            item.value = ""
          }
        }
      })
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
      }
      this.total = 0
      this.search()
    },
    // 获取列表数据
    async GetSearchList() {
      // console.log('this.searchParam', this.searchParam);
        let params = {
            page: this.pageParam.pageNum,
            limit: this.pageParam.pageSize,
            ...this.searchParam
        }
        const { code, result, msg} = await getEmploymentInformationList(params)
        this.listLoading = true
        if (code == 0) {
          this.listLoading = false
          this.tableData = result.records || []
          this.total = result.total
        } else {
          this.listLoading = false
          this.$message.error(msg)
        }
    },
    clickRow() {

    },
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.GetSearchList()
    },
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.GetSearchList()
    },
    // 删除
    deleteClick(val) {
      let params = {
        id: val.id,
        isExist: 1,
        updateId: this.$store.state.user.loginUser.userId,
        updateTime: formatTimes(new Date())
      }
      this.$hqMessageBox({
      message:
        `确认删除学员 ${val.studentName} 的就业信息？`,
      })
      .then(() => {
        return new Promise(async (resolve, reject) => { 
          const { code,  msg} = await employmentInformationUpdate(params)
          if (code == 0) {
            this.$message.success("删除成功！");
            this.search();
          } else {
            this.$message.warning(msg);
          }
        })
      })
      .catch(() => {
        console.log("取消");
      })
    },
    // 打开详情
    openAddEditDetail(status,row) {
      // this.showAddEditDetail = true;
      this.$refs.drawer.init(status,row)
    },
  },
  computed: {
    ...mapGetters([
      "getTyUserInfo", "loginUser"
    ]),
  tableHeight() {
    return window.outerHeight - this.topHeight - 256 + 'px'
  }
},
  mounted() {
    console.log('2025-04-03')
    this.searchParamList = this.searchAllParam;
    this._getSearchAll()
    this.search()
    // this.GetAddList()
  }
}
</script>

<style lang="scss" scoped>
.lineclass {
  margin-top:20px;
  height: 10px;
  width: 100%;
  display: inline-block;
}
.customer {
// padding-bottom: 40px;
.titie {
  text-align: left;
}

.tool {
  background: #f5f7f9;
}

.customer-tool {
  display: flex;
  justify-content: space-between;
  width: 94%;
  margin: 0 auto;

  .tab-bar {
    overflow: hidden;

    .active {
      color: #409eff;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: #337aff;
        bottom: 0;
        left: 0;
      }
    }

    > span {
      float: left;
      margin-right: 12px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      padding: 0 10px;
    }
  }
}
.phone {
  .el-button--text {
    padding: 10px 4px;
  }
}
}
::v-deep .action_col{
padding: 4px 0 !important;
}
.action_btn{
width: 60px;
padding: 0;
height: 24px;
line-height: 24px;
background-color: #337AFF;
font-size: 13px;
&.is-disabled{
  background-color: #E1E4EB;
  border-color: #E1E4EB;
}
}
</style>
