<!--
 * @Description: 我的客户
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-22 16:36:56
 * @LastEditors: Seven
-->
<template>
  <div class="customer">
    <div ref="contentTop">
      <div class="tool">
        <div class="customer-tool">
          <div>
            <span class="tab-bar clearfix">
              <span
                @click="handleClick(item.path)"
                v-for="(item, index) in tabList"
                :class="{ active: item.path === activeName }"
                :key="index"
                >{{ item.name }}</span
              >
            </span>
          </div>
          <div style="line-height: 40px">
            <el-button
              type="primary"
              class="hq-button"
              :disabled="!currList.length || currList.length>1"
              v-if="permUtil.WbCusotmer.saverannles()"
              @click.stop="changeCustomer"
              >
                变更渠道名称
            </el-button>
            <el-button
              type="primary"
              class="hq-button"
              v-if="permUtil.WbCusotmer.addBatchReserve()"
              :disabled="!currList.length"
              @click.stop="saveRemoveCustomer(1)"
              >
                移入留存
            </el-button>
            <el-button
              v-if="permUtil.WbCusotmer.addBatchReserve()"
              type="primary"
              class="hq-button"
              :disabled="!currList.length"
              @click.stop="saveRemoveCustomer(0)"
              >
                移出留存
            </el-button>
            <!--  查询转移 -->
             <TransferSearch :setTransrefseach="setTransrefseach" v-if="permUtil.WbCusotmer.customerTransfersearch()" ref="transfersearch" :searchTranch="searchTranch" :method="getcustomertrachList" :pageParam="pageParam" :searchParam="searchParam" :total="total" :exportType="exportType" />
            <!-- 新增 -->
            <Add v-if="permUtil.WbCusotmer.customerSave()" :method="getcustomerList" :channelsList="channelsList" :recruitChannelsList="recruitChannelsList" />
            <!-- 无效 -->
            <Invalid v-if="permUtil.WbCusotmer.customerInvalid()" :currCol="currCol" :method="getcustomerList" />
            <!-- 转移 -->
            <Transfer @fatherMethod="fatherMethod" :setTransref="setTransref" v-if="permUtil.WbCusotmer.customerTransfer()" ref="transfer" :searchTranch="false" :method="getcustomerList" :pageParam="pageParam" :searchParam="searchParam" :total="total" :exportType="exportType" />
            <!-- 导出 -->
            <Export v-if="permUtil.WbCusotmer.customerExport()" :pageParam="pageParam" :searchParam="searchParam" :total="total" :exportType="exportType" />
            <!-- 导入 -->
            <Import v-if="permUtil.WbCusotmer.customerImport()" :method="getcustomerList" />
            <!-- 退回公海 -->
            <el-button v-if="permUtil.WbCusotmer.customerReturn()" @click="giveUp" :disabled="currList.length === 0" type="success" class="hq-button" icon="el-icon-error">放弃</el-button>
          </div>
        </div>
      </div>
      <div
        :gutter="10"
        style="width: 94%; margin: 0 auto"
      >
        <Label 
          ref="LabelBiz"
          :isClick="isClick"
          :defaultClick="defaultClick"
          v-show="loginUser.trackType === 1 && $route.query.type == 'all'"
          @searchList="labelSearch" 
        />
        <Search
          v-if="searchParamList.length > 0"
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          @searchMore="searchMore"
          @searchList="searchList"
          :searchParamList="searchParamList"
          :searchParamFromApi="searchParamFromApi"
          code="customer"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @selection-change="selectionRow"
          :row-class-name="tableRowClassName"
          @sort-change="sortChange"
          @row-click="clickRow"
          :height="tableHeight"
        >
          <el-table-column
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column 
            :show-overflow-tooltip="true"
            label="学员姓名"
            prop="name"
            width="150"
            sortable="custom"
            fixed="left"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{ scope.row.name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column 
            :show-overflow-tooltip="true"
            label="手机"
            prop="phone"
            width="150"
            fixed="left"
          >
            <template slot-scope="scope">
              <div class="phone" v-if="!!scope.row.phone">
                <span>{{ scope.row.phone }}</span>
                <el-button v-if="permUtil.WbCusotmer.customerSeePhone()" @click="seeShowClick(scope.row)" type="text" icon="el-icon-document-copy"></el-button>
                <!-- <el-button @click="autoCall(scope.row)" type="text" icon="el-icon-phone"></el-button> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="意向状态"
            prop="followStatusName"
            width="120"
            :sort-by="['followStatusId']"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="归属人"
            prop="userName"
            width="120"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="归属部门"
            prop="deptName"
            width="120"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="微信"
            prop="weChat"
            width="130"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="微信状态"
            prop="wxVerification"
            width="100"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{scope.row.wxVerificationName}}
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进时间"
            prop="followTime"
            width="150"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建时间"
            prop="createTime"
            width="150"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="回收时间"
            prop="expireTime"
            width="150"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="分配时间"
            prop="allotTime"
            width="150"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="商机状态"
            prop="statusName"
            width="120"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进次数"
            prop="followNumber"
            width="120"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="下次联系时间"
            prop="nextFollowTime"
            width="150"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建人"
            prop="createName"
            width="120"
            
          ></el-table-column>
          <el-table-column
            label="操作"
            prop=""
            width="220"
            
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">详情</el-button>
              <el-button type="text" v-if="scope.row.isReserve === 0 && permUtil.WbCusotmer.customerReserve()&&scope.row.userName" @click="reserve(scope.row, 1)">移入留存</el-button>  
              <el-button type="text" v-if="scope.row.isReserve === 1 && permUtil.WbCusotmer.customerReserve()&&scope.row.userName" @click="reserve(scope.row, 0)">移出留存</el-button>  
              <el-button v-if="permUtil.WbCusotmer.customerAppoint()&&scope.row.userName" type="text" @click="appoint(scope.row)">预约</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <Detail ref="drawer" :method="getcustomerList" code="customer" />
    <!-- 预约 -->
    <Appoint ref="appoint" :method="getcustomerList" />
    <!--变更渠道名称  -->
    <HqModal :visible="changeShow" title="变更渠道名称" width="400" @on-cancel="cancel">
      <el-form ref="form" lazy-validation>
        <el-row :gutter="10">
          <el-col :span="24" style="margin-bottom: 20px">
            <span>原渠道名称:</span>
            <span>{{recruitChanne.recruitChannelsName}}</span>
          </el-col>
          <br />
          <el-col :span="24">
            <el-form-item prop="recruitChannelsId">
              <v-autocomplete
                  v-model="form.recruitChannelsId"
                  :items="recruitChannelsselect"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="渠道名称"
                  item-text="name"
                  item-value="id"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  @change="Changeselect"
                ></v-autocomplete>
              </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.recruitChannelsId === 1723">
                <el-form-item prop="flowPartner">
                  <v-autocomplete
                    v-model="form.flowPartner"
                    :items="partnerList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="合伙人（流量归属人）"
                    item-text="flowPartner"
                    item-value="flowPartner"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    @change="partnerClick"
                  ></v-autocomplete>
                </el-form-item>
              </el-col>
          <el-col :span="24" v-if="form.recruitChannelsId === 1723">
             <el-form-item prop="flowPartnerPhone">
              <v-text-field
                v-model="form.flowPartnerPhone"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                label="合伙人电话"
              ></v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.recruitChannelsId === 1723">
              <el-form-item prop="economicFlowPartner">
                <v-text-field
                  v-model="form.economicFlowPartner"
                  label="经纪合伙人（第二流量归属人）"
                  hide-details="auto"
                  readonly
                  outlined
                  :dense="true"
                  :height="32"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="form.recruitChannelsId === 1723">
              <el-form-item prop="economicFlowPartnerPhone">
                <v-text-field
                  v-model="form.economicFlowPartnerPhone"
                  label="经纪合伙人电话"
                  hide-details="auto"
                  readonly
                  outlined
                  :dense="true"
                  :height="32"
                ></v-text-field>
              </el-form-item>
            </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="changeSave" type="primary">确认</el-button>
      </div>
    </HqModal>
  </div>
</template>
<script>
import Search from "components/search";
import Add from "./components/add";
import Import from "./components/import";
import Invalid from "./components/invalid";
import Transfer from "./components/transfer";
import TransferSearch from "./components/transfersearch";
import Detail from "./components/detail";
import Appoint from "./components/appoint";
import Export from "./components/export";
import Label from "./components/label";
import { 
  getUserCustomQuery, 
  transferDeptTree, 
  getUserList, 
  getProxySpinner, 
  getRecruitSpinner, 
  getTypeSpinner,
  getTrackAreaTree,
  getDeptUserTree,
  getRecruitName,
  getflowPartnerList,
  getRecruitSave,
  sysUserList,
  getRecruitSpinnernew
} from "api/public";
import { 
  getcustomerList, 
  getDefLableListLabel, 
  doAddReserve, 
  doReturnToCommon, 
  getReserveJudge,
  getJudgetLookContack,
  doOperationSave,
  doSeePhone,
  doJiamiSeePhone,
  receiveBatch
} from "api/customer";
import { mapActions, mapGetters } from "vuex";
import {MessageBox} from 'element-ui'
import CryptoJS from "crypto-js";
import Crypto from "@/utils/crypto";
import Strhide from "@/utils/strhide";



export default {
  components: {
    Search,
    Add,
    Import,
    Invalid,
    Transfer,
    Detail,
    Appoint,
    Export,
    Label,
    TransferSearch
  },
  data() {
    return {
      setTransrefseach: true,
      setTransref: true,
      partnerList: [],
      changeShow: false,
      form: {},
      recruitChannelsselect: [],
      activeName: "all",
      tabList: [
        { name: "全部", path: "all" },
        { name: "今日客户", path: "today" },
        { name: "领取客户", path: "revice" },
        { name: "留存客户", path: "keep" },
      ],
      tableData: [],
      listLoading: false,
      searchAllParam: [
        { name: "phone", label: "手机", type: "input", value: "" },
        { 
          name: "createTime",
          label: "创建时间", 
          type: "date", 
          value: [],
          ref: "create",
          show: false 
        },
        {
          name: "followStatus",
          label: "意向状态",
          type: "dictMult",
          code: "",
          value: [],
        },
        { name: "name", label: "学员姓名", type: "input", value: "", },
        { 
          name: "allotTime", 
          label: "分配时间", 
          type: "date", 
          value: [], 
          ref: "allot", 
          show: false 
        },
        { 
          name: "followTime", 
          label: "跟进时间", 
          type: "date", 
          value: [], 
          ref: "follow", 
          show: false 
        },
        {
          name: "userId",
          label: "归属销售",
          type: "select",
          option: [],
          value: "",
          text: "name",
          id: "id"
        },
        
        {
          name: "deptIdList",
          label: "归属部门",
          type: "multiTree",
          option: [],
          value: 0,
        },
        { name: "url", label: "URL", type: "input", value: "", },
        {
          name: "status",
          label: "商机状态",
          type: "dict",
          code: "BIZ_STATUS",
          value: [0, 2, 3, 4],
          multiple: true,
        },
        { name: "customerId", label: "客户编号", type: "input", value: "", },
        { name: "weiXin", label: "微信", type: "input", value: "", },
        { name: "qq", label: "QQ", type: "input", value: "", },
        {
          name: "isReserve",
          label: "是否留存",
          type: "select",
          option: [{name: "是", id: 1}, {name: "否", id: 0}],
          value: "",
          text: "name",
          id: "id",
        },
        // {
        //   name: "channels",
        //   label: "代理网名称",
        //   type: "allSelect",
        //   option: [],
        //   value: [],
        //   text: "name",
        //   id: "id",
        // },
        {
          name: "recruitChannels",
          label: "渠道名称",
          type: "allSelect",
          option: [],
          value: [],
          text: "name",
          id: "id"
        },
        { name: "cswShou", label: "关键字", type: "input", value: "", },
        { name: "memo", label: "备注", type: "input", value: "", },
        {
          name: "sources",
          label: "商机来源",
          type: "dictMult",
          code: "BIZ_SOURCE",
          value: [],
        },
        {
          name: "esLabels",
          label: "客户标签",
          type: "select",
          option: [],
          value: "",
          text: "labelName",
          id: "labelId",
        },
        {
          name: "channelsTypeName",
          label: "渠道类别",
          type: "allSelect",
          option: [],
          value: [],
          text: "name",
          id: "id"
        },
        {
          name: "isDelivery",
          label: "是否交付",
          type: "select",
          option: [{name: "未交付", id: 0}, {name: "已交付", id: 1}],
          value: "",
          text: "name",
          id: "id",
        },
        // { 
        //   name: "areaType", 
        //   label: "区域", 
        //   type: "dict", 
        //   code: "BASE_ARERS_TYPE", 
        //   value: "", 
        // },
        // {
        //   name: "careerType",
        //   label: "职业状态",
        //   type: "dict",
        //   code: "BASE_CAREER_TYPE",
        //   value: "",
        // },
        // {
        //   name: "demandType",
        //   label: "客户需求",
        //   type: "dict",
        //   code: "BASE_DEMAND_TYPE",
        //   value: "",
        // },
        // {
        //   name: "calloutType",
        //   label: "呼出状态",
        //   type: "dict",
        //   code: "BASE_CALLOUT_TYPE",
        //   value: "",
        // },
        {
          name: "education",
          label: "学历",
          type: "dict",
          code: "EDUCATION_TYPE",
          value: "",
        },
        { name: "createId", 
          label: "创建人",
          type: "select",
          option: [],
          value: "",
          text: "userName",
          id: "userId",
        },{
          name: "existUser",
          label: "归属人为空",
          type: "select",
          option: [{name: "是", id: 0}, {name: "否", id: 1}],
          value: "",
          text: "name",
          id: "id",
        },
        {
          name: "wxVerification",
          label: "微信状态",
          type: "select",
          option: [{name: "已发送", id: 0}, {name: "已添加", id: 1}, {name: "未发送", id: 2}],
          value: "",
          text: "name",
          id: "id",
        },
      ],
      searchParamList: [],
      defaultParamList: ["phone", "createTime", "followStatus"],
      searchParamFromApi:[],
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "createTime",
          },
        ],
      },
      // 列表
      currList: [],
      // 部门
      deptList: [],
      // 人员
      userList: [],
      currCol: {
        status: 1
      },
      statusArr: [],
      customerIdList: [],
      url: "listPage",
      channelsList: [],
      recruitChannelsList: [],
      labelList: [],
      // 导出类型
      exportType: 1,
      searchParam: {},
      // 页面是否有滚动条
      isScroll: true,
      topHeight: 0,
      isClick: true,
      defaultClick: true,
      recruitChanne: {},
      searchTranch: false,
      showClick: true,
    };  
  },
  computed: {
    ...mapGetters(["loginUser"]),
    getTrackType() {
      return this.loginUser.trackType === 1 || this.loginUser.trackType === 3
        ? "FOLLOW_STATUS_ACCOUNT"
        : "FOLLOW_STATUS_SELF_EXAM";
    },
    tableHeight() {
      return window.outerHeight - this.topHeight - 256 + 'px'
    }
  },
  mounted() {
    this.activeName = this.$route.query.type || "all"
    
    this.getSearchList();
    this.handleClick(this.activeName)
    transferDeptTree({ isRoot: false }).then((data) => this.deptList = data.result.tree);
    this.searchAllParam.map((item) => {
      if (item.name === "followStatus") {
        item.code = this.getTrackType
      } else if(item.name === "deptIdList") {
        getDeptUserTree().then((data) => item.option = data.result);
      } else if (item.name === "userId") {
        getUserList().then((data) => {
          item.option = data.result;
          this.userList = data.result;
        });
      } else if (item.name === "esLabels") {
        getDefLableListLabel().then(data => {
          item.option = data.result
          this.labelList = data.result
        });
      } else if (item.name === "channels") {
        getProxySpinner().then(data => {
          item.option = data.result
          this.channelsList = data.result
        })
      } else if (item.name === "recruitChannels") {
        getRecruitSpinner().then(data => {
          item.option = data.result
        })
      } else if (item.name === "channelsTypeName") {
        getTypeSpinner().then(data => item.option = data.result)
      } else if (item.name === "areaType") {
        getTrackAreaTree().then(data => item.option = data.result.tree)
      } else if (item.name === 'createId') {
        sysUserList().then(res => {
          item.option = res.result
        })
      }
    });
    getRecruitSpinnernew().then(data => {
      this.recruitChannelsList = data.result
    })
    this.setTransrefseach = true
    this.setTransref = true
  },
  methods: {
    ...mapActions([
      "actionTinetCallOutAndGoto",
    ]),
    tableRowClassName({row}) {
      return row.isReserve === 1? 'reserve-row':''
    },
    partnerClick(value) {
       this.partnerList.map(item => {
          if(item.flowPartner === value) {
            this.form.flowPartnerCode = item.flowPartnerCode
            this.form.flowPartner = item.flowPartner
            this.form.flowPartnerPhone = item.flowPartnerPhone
            this.form.economicFlowPartnerCode = item.economicFlowPartnerCode
            this.form.economicFlowPartner = item.economicFlowPartner
            this.form.economicFlowPartnerPhone = item.economicFlowPartnerPhone
          }
        })
    },
    /**批量移出留存 */
    saveRemoveCustomer(type=1){
      /**批量留存为了提示的顺序，去除提交前的参数教研 */
      /**移入留存 */
      // if(type){
      //     /**是否有归属人 */
      //   let isHasUserName = this.currList.find(item=>!item.userName)
      //   if(isHasUserName){
      //     this.$message.warning('移入留存中存在无归属人的商机，暂时无法进行该操作')
      //     return
      //   }
      //   /**归属人状态 */
      //   // let isDisabled = this.currList.find(item=>!item)
      //   // if(isDisabled){
      //   //   this.$message.warn('商机所属人状态为“禁用”，暂时无法进行该操作！')
      //   //   return
      //   // }
      //   let isReservePotential = this.currList.find(item=>{
      //     return item.status!==0||item.status!==2
      //   })
      //   if(isReservePotential){
      //     this.$message.warning('存在非潜在｜预约状态的商机，暂时无法进行该操作！')
      //     return
      //   }
      //   let isReserve = this.currList.find(item=>{
      //     return item.isReserve===1
      //   })
      //   if(isReserve){
      //     this.$message.warning('已在留存库中的商机无需再次移入留存，请去除已在留存库中的商机再操作！')
      //     return
      //   }
      // }
      
      MessageBox.confirm(`是否将这${this.currList.length}条数据移${type?'入':'出'}留存`,type? '移入留存':'移出留存', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose:(action,instance,done)=>{
            if(action==='confirm'){
              let userId = this.$store.getters.loginUser.userId; 
              let customerIds = this.currList.map(({customerId})=>{
                return customerId
              })
              instance.confirmButtonLoading = true
              receiveBatch({
                customerIds,
                sign:type,
                userId
              }).then(({code,msg})=>{
                if(code!==0){
                  this.$message.warning(msg)
                  done()
                  return
                }
                this.getcustomerList()
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                done()
              }).finally(()=>{
                instance.confirmButtonLoading = false
                
              })
              return
            }
            done()
          }
        }).then(() => {
          
        })
    },
    // 变更渠道名称
    changeCustomer() {
      console.log('this.currList', this.currList);
      this.getrecruitName()
      getRecruitSpinnernew().then(data => {

        this.recruitChannelsselect = data.result
        this.form = {}
        this.changeShow = true
      })
    },
    // 获取渠道名称
    getrecruitName() {
      getRecruitName(this.currList[0].customerId).then(res => {
        if (res.code == 0) {
          console.log(res,'---------');
          this.recruitChanne = res.result ? res.result : {}
        }
      })
    },
    cancel() {
      this.form = {}
      this.changeShow = false
    },
    // 变更渠道名称确定
    changeSave() {
      if (!this.form.recruitChannelsId) {
        this.$message.error('请选择变更渠道')
      } else if (this.form.recruitChannelsId == 1723 && !this.form.flowPartner) {
        this.$message.error('请选择合伙人')
      } else {
        let params = {
          customerId: this.currList[0].customerId,
          ...this.form
        }
        getRecruitSave(params).then(res => {
          if (res.code == 0) {
            this.changeShow = false
            this.$message.success('成功')
            this.getcustomerList()
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    Changeselect (val) {
      if (val !== 1723) {
        this.form = {}
      } else {
        getflowPartnerList().then(data => this.partnerList = data.result)
      }
      this.form.recruitChannelsId = val
      console.log('val选择',val, this.form, this.form.recruitChannelsId);
    },
    // tab切换
    handleClick(index) {
      this.activeName = index;
      this.showClick = true
      console.log('this.setTransrefseach', this.setTransrefseach);
      switch (index) {
        case "all":
          this.url = "listPage"
          this.exportType = 1
          break;
        case "today":
          this.url = "todayListPage"
          this.exportType = 2
          break;
        case "revice":
          this.url = "receiveListPage"
          this.exportType = 3
          break;
        case "privte":
          this.url = "privateListPage"
          this.exportType = 4
          break;
        case "keep":
          this.url = "reserveListPage"
          this.exportType = 5
          break;
        default:
          break;
      }
      this.$router.push({ query: { type: this.activeName } });
      this.searchclick()
    },
    // 查询
    searchclick() {
      this.$refs.LabelBiz.getDefLableGetDef()
      this.searchParam = {}
      this.searchAllParam.map(item => {
        if(item.value===0){
          this.searchParam[item.name] = item.value
        }
        if(item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if(item.type === "date") {
            if(item.name === "createTime") {
              this.searchParam.beginCreateTime = item.value[0];
              this.searchParam.endCreateTime = item.value[1];
            } else if(item.name === "allotTime") {
              this.searchParam.beginAllotTime = item.value[0];
              this.searchParam.endAllotTime = item.value[1];
            } else if(item.name === "followTime") {
              this.searchParam.beginFollowTime = item.value[0];
              this.searchParam.endFollowTime = item.value[1];
            }
          } else {
            if(item.name === "esLabels") {
              this.labelList.map(i => {
                if(i.labelId === item.value) {
                  this.searchParam.labelId = item.value
                  this.searchParam.labelTrckType = i.labelTrckType
                }
              })
            }
            this.searchParam[item.name] = item.value 
          }
        }
      })
      if (this.searchParam.existUser == 0) {
        this.searchParam.existUser = false
      } else if (this.searchParam.existUser == 1){
        this.searchParam.existUser = true
      } else {
        this.searchParam.existUser = ''
      }
      console.log('this.searchParam',this.searchParam.existUser);
      this.searchTranch = true
      this.setTransref = true
      this.setTransrefseach = true
      this.getcustomerListsearch()
    },
    // 跟据标签查询列表
    labelSearch(value) {
      this.esLabels = "";
      // const isClickLabel = true;
      // 通过客户标签查询的话要先重置查询条件
      this.resetParams()
      // this.searchRset({}, isClickLabel);
      this.searchParam.labelId = value.labelId;
      this.searchParam.labelTrckType = value.labelTrckType;
      this.defaultClick = value.labelId;
      this.isClick = false;
      this.getcustomerList();
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList()
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
      console.log('this.searchParamList', this.searchParamList);
      this.$nextTick(() => {
        this.topHeight = this.$refs.contentTop.offsetHeight + 30
      })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const {code, result } = await getUserCustomQuery('customer');
      if(code === 0) {
        this.defaultParamList = ["phone", "createTime", "followStatus"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          
          this.searchParamFromApi = JSON.parse(result.fieldData)
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 8
        })
      }
    },
    // 获取列表数据
    async getcustomertrachList() {
      this.listLoading = true;
      const { code, result, msg } = await getcustomerList({...this.pageParam, ...this.searchParam}, this.url);
      if(code === 0) {
        result.list.map(item => {
          if(item.phone) {
            item.seeShow = false
          }
        })
        this.tableData = result.list;
        this.total = result.total;
        this.isClick = true
        this.setTransrefseach = true
        this.currCol = {
          status: 1
        }
        this.$nextTick(() => {
          this.$refs.transfer && this.$refs.transfer.init([])
          // this.$refs.transfersearch && this.$refs.transfersearch.init(this.tableData)
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 获取列表数据
    async getcustomerList() {
      this.listLoading = true;
      const { code, result, msg } = await getcustomerList({...this.pageParam, ...this.searchParam}, this.url);
      if(code === 0) {
        result.list.map(item => {
          if(item.phone) {
            item.seeShow = false
          }
        })
        this.tableData = result.list;
        this.total = result.total;
        this.isClick = true
        this.currCol = {
          status: 1
        }
        this.$nextTick(() => {
          this.$refs.transfer && this.$refs.transfer.init([])
          // this.$refs.transfersearch && this.$refs.transfersearch.init(this.tableData)
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 单选事件
    clickRow(row) {
      this.currCol = row;
    },
    // 多选事件--
    selectionRow (val) {
      this.statusArr = []
      this.customerIdList = []
      this.currList = val;
      this.searchTranch = false
      val.map(item => {
        this.statusArr.push(item.status)
        this.customerIdList.push(item.customerId)
      })
      // console.log('this.currList', this.currList);
      this.$refs.transfer && this.$refs.transfer.init(this.currList)
      this.$refs.transfersearch && this.$refs.transfersearch.init(this.currList)
      this.setTransref = false
      console.log('val多选事件', val);
      console.log('setTransref-------------',this.setTransref);
    },

    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getcustomerList()
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getcustomerList()
    },
    // 排序
    sortChange(val) {
      this.pageParam.sortItems[0].asc = !(val.order == "descending")
      if(val.prop === "followStatusName") {
        this.pageParam.sortItems[0].column = "followStatus"  
      } else if(val.prop === "deptName") {
        this.pageParam.sortItems[0].column = "deptId"
      } else if(val.prop === "statusName") {
        this.pageParam.sortItems[0].column = "status"
      } else if(val.prop === "userName") {
        this.pageParam.sortItems[0].column = "userId"
      } else {
        this.pageParam.sortItems[0].column = val.prop;
      }
      this.getcustomerList();
    },
    fatherMethod() {
      this.setTransref = true;
      console.log('调用了', this.setTransref);
    },
    // 查询
    search() {
      this.$refs.LabelBiz.getDefLableGetDef()
      this.searchParam = {}
      this.searchAllParam.map(item => {
        if(item.value===0){
          this.searchParam[item.name] = item.value
        }
        if(item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if(item.type === "date") {
            if(item.name === "createTime") {
              this.searchParam.beginCreateTime = item.value[0];
              this.searchParam.endCreateTime = item.value[1];
            } else if(item.name === "allotTime") {
              this.searchParam.beginAllotTime = item.value[0];
              this.searchParam.endAllotTime = item.value[1];
            } else if(item.name === "followTime") {
              this.searchParam.beginFollowTime = item.value[0];
              this.searchParam.endFollowTime = item.value[1];
            }
          } else {
            if(item.name === "esLabels") {
              this.labelList.map(i => {
                if(i.labelId === item.value) {
                  this.searchParam.labelId = item.value
                  this.searchParam.labelTrckType = i.labelTrckType
                }
              })
            }
            this.searchParam[item.name] = item.value 
          }
        }
      })
      if (this.searchParam.existUser == 0) {
        this.searchParam.existUser = false
      } else if (this.searchParam.existUser == 1){
        this.searchParam.existUser = true
      } else {
        this.searchParam.existUser = ''
      }
      console.log('this.searchParam',this.searchParam.existUser);
      this.searchTranch = true
      this.setTransref = true
      this.setTransrefseach = false
      this.getcustomerListsearch()
    },
    // 获取列表数据
    async getcustomerListsearch() {
      this.listLoading = true;
      const { code, result, msg } = await getcustomerList({...this.pageParam, ...this.searchParam}, this.url);
      if(code === 0) {
        result.list.map(item => {
          if(item.phone) {
            item.seeShow = false
          }
        })
        this.tableData = result.list;
        this.total = result.total;
        this.isClick = true
        this.currCol = {
          status: 1
        }
        this.$nextTick(() => {
          console.log('this.tableData', this.tableData, this.$refs.transfer);
          this.$refs.transfer && this.$refs.transfer.init(this.tableData)
          this.$refs.transfersearch && this.$refs.transfersearch.init(this.tableData)
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 重置参数
    resetParams() {
      this.setTransrefseach = true
      this.searchParamList.map(item => {
        if(item.value || item.value == 0) {
          if(item.name === "createTime" || 
          item.name === "allotTime" ||
          item.name === "followTime" ||
          item.name === "channels" ||
          item.name === "recruitChannels" ||
          item.name === "sources" ||
          item.name === "channelsTypeName") {
            item.value = []
          } else if(item.name === "status") {
            // item.value = [0, 2, 3, 4]
            item.value = []
          } else {
            item.value = ""
          }
        }
      })
      // this.searchParam = { 
      //   sources: [0, 2, 3, 4]
      // }
      this.pageParam = {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "createTime"
          }
        ],
      }
    },
    // 重置
    reset() {
      this.resetParams()
      this.$refs.LabelBiz.getDefLableGetDef()
      this.searchTranch = false
      this.setTransref = true
      this.searchreset()
    },
    // 查询
    searchreset() {
      this.$refs.LabelBiz.getDefLableGetDef()
      this.searchParam = {}
      this.searchAllParam.map(item => {
        if(item.value===0){
          this.searchParam[item.name] = item.value
        }
        if(item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if(item.type === "date") {
            if(item.name === "createTime") {
              this.searchParam.beginCreateTime = item.value[0];
              this.searchParam.endCreateTime = item.value[1];
            } else if(item.name === "allotTime") {
              this.searchParam.beginAllotTime = item.value[0];
              this.searchParam.endAllotTime = item.value[1];
            } else if(item.name === "followTime") {
              this.searchParam.beginFollowTime = item.value[0];
              this.searchParam.endFollowTime = item.value[1];
            }
          } else {
            if(item.name === "esLabels") {
              this.labelList.map(i => {
                if(i.labelId === item.value) {
                  this.searchParam.labelId = item.value
                  this.searchParam.labelTrckType = i.labelTrckType
                }
              })
            }
            this.searchParam[item.name] = item.value 
          }
        }
      })
      if (this.searchParam.existUser == 0) {
        this.searchParam.existUser = false
      } else if (this.searchParam.existUser == 1){
        this.searchParam.existUser = true
      } else {
        this.searchParam.existUser = ''
      }
      console.log('this.searchParam',this.searchParam.existUser);
      
      this.getcustomerList()
    },
    // 复制号码
    async seeShowClick(v) {
      let _this = this
      if(!v.seeShow) {
        getJudgetLookContack().then(async data => {
          if(data.result) {
            console.log(v.customerId.toString());

            let str = v.customerId.toString()
            const params = {
              // detail: v.phone,
              operationType: 1,
              customerId: v.customerId,
              operationKey: 'getDetailByCustomerId'
            }
            if (v.type == 0) {
              params.operationType = 1
            }
            
            let res = await doJiamiSeePhone(Crypto.encrypt(Strhide.getKeyHide(),Strhide.getIvHide(), str))
            console.log('res复制号码',res);
            // if(res.code === 0) {
            //   v.oldPhone = v.phone
            //   v.phone = res.result
            //   v.seeShow = !v.seeShow
            //   params.detail = res.result
            //   await doOperationSave(params)  
            // }
            this.$forceUpdate()
            this.$copyText(res.result).then(function () {
              _this.$message.success("已复制号码")
            }, function () {
              _this.$message.error("抱歉，复制号码失败")
            })
          } else {
            v.seeShow = false
            this.$forceUpdate()
            _this.$message.error("抱歉，复制号码失败")
          }
        })
      } else {
        v.phone = v.oldPhone
        delete v.oldPhone
        v.seeShow = !v.seeShow
        this.$forceUpdate()
      }
    },
    // 放弃
    giveUp() {
      console.log('this.statusArr', this.statusArr);
      if ((this.statusArr.length === 1 && this.statusArr[0] == 0) || this.statusArr[0] == 2) {
        const elementsAreEqual = array => array.every(el => el === 0 || 2)
        if(!elementsAreEqual(this.statusArr)) {
          this.$message.error('所选商机为非潜在和预约状态商机，无法退回公海。');
          return false
        }
        
        let length = this.currList.length;
        let value = this.currList[0].customerId;
        if (this.currList.length > 1) {
          value = `${value}等${length}条商机`
        }
      this.$hqMessageBox({
        message:
          "请确定是否将商机（" + value + "）退回到公海？请注意退回后将无法再进行跟进",
        })
        .then(() => {
          doReturnToCommon(this.customerIdList).then((data) => {
            const { code, msg, result } = data
            if (code === 0) {
              this.$message.success(result)
              this.getcustomerList()
            } else {
              this.$message.error(msg)
            }
          })
        })
        .catch(() => {
          console.log("取消");
        })
      } else if (this.statusArr.length > 1) {
        const elementsAreEquasl = array => array.every(el => el === 0 || 2)
        console.log('elementsAreEquasl', elementsAreEquasl);
        if(!elementsAreEquasl(this.statusArr)) {
          this.$message.error('所选商机为非潜在和预约状态商机，无法退回公海。');
          return false
        }
        let length = this.currList.length;
        let value = this.currList[0].customerId;
        if (this.currList.length > 1) {
          value = `${value}等${length}条商机`
        }
      this.$hqMessageBox({
        message:
          "请确定是否将商机（" + value + "）退回到公海？请注意退回后将无法再进行跟进",
        })
        .then(() => {
          doReturnToCommon(this.customerIdList).then((data) => {
            const { code, msg, result } = data
            if (code === 0) {
              this.$message.success(result)
              this.getcustomerList()
            } else {
              this.$message.error(msg)
            }
          })
        })
        .catch(() => {
          console.log("取消");
        })
      } else {
        this.$message.error('所选商机为非潜在和预约状态商机，无法退回公海。');
          return false
      }
      // if((this.statusArr.length === 1 && this.statusArr[0] !== 0) || this.statusArr[0] !== 2) {}
        
    },
    // 打开详情
    openDetail(row) {
      this.$refs.drawer.init(row)
    },
    // type 0:解锁，1锁定，移入留存
    reserve(row, type) {
      // 需要先判断是否达到留存上限
      getReserveJudge(row.customerId, type).then(res => {
        if(res.code === 0) {
          this.$hqMessageBox({
            title: "留存",
            message: res.result,
          })
            .then(async () => {
              const { code, msg } = await doAddReserve(row.customerId, type)
              if(code === 0) {
                this.$message.success(msg)
                this.getcustomerList()
              } 
            })
            .catch(() => {
              console.log("取消");
          });
        } else if(res.code === -1) {
          this.$message.info(res.msg)
        }
      })
    },
    // 预约
    appoint(row) {
      this.$refs.appoint.init(row)
    },
    // 一键外呼
    doRecord(v, result) {
      const telList = {
        tel: result,
      }
      this.$store.dispatch('actionTinetCallOut', {tel: telList, customerId: v.customerId})
    },
    // 外呼记录
    async autoCall(v) {
      try {
        let str = v.customerId
        
        let res = await doJiamiSeePhone(Crypto.encrypt(Strhide.getKeyHide(),Strhide.getIvHide(), str))
        if(res.code === 0) {
          this.doRecord(v, res.result)
        } 
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.customer {
  .titie {
    text-align: left;
  }

  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;

    .tab-bar {
      overflow: hidden;

      .active {
        color: #409eff;
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: #337aff;
          bottom: 0;
          left: 0;
        }
      }

      > span {
        float: left;
        margin-right: 12px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }
  .phone {
    .el-button--text {
      padding: 10px 4px;
    }
  }
}

.disable {
  pointer-events: none;
}

::v-deep .reserve-row {
  font-weight: 900;
  color:black;
}
</style>
